import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Overlay from 'components/commercetools-ui/atoms/overlay';

export const Layout: React.FC = ({ children }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url: any) => setIsLoading(true);
    const handleComplete = (url: any) => setIsLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
    };
  }, [router.events]);

  return (
    <>
      {isLoading && <Overlay showSpinner={false} />}
      {children}
    </>
  );
};

export default Layout;
