import React from 'react';

interface Props {
  onClick?: () => void;
  showSpinner?: boolean;
}

const Overlay: React.FC<Props> = ({ onClick, showSpinner = true }) => {
  //eslint-disable-next-line tailwindcss/no-custom-classname
  return (
    <div className="fixed left-0 top-0 z-[100] h-full w-full bg-[#7f7f7f]/40" onClick={onClick}>
      <div className="flex h-screen items-center justify-center">
        {showSpinner && (
          <svg className="z-[300] h-60 w-60 animate-spin" viewBox="0 0 100 100">
            <circle fill="none" strokeWidth="10" className="stroke-current opacity-40" cx="50" cy="50" r="40" />
            <circle
              fill="none"
              strokeWidth="10"
              className="stroke-current"
              strokeDasharray="250"
              strokeDashoffset="210"
              cx="50"
              cy="50"
              r="40"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default Overlay;
