import { useCallback, useContext, useState } from 'react';
import { UseGeoDataReturn } from './type';
import { GlobalContext } from 'frontastic/context/globalContext';
import { sdk } from 'sdk';
import { Result } from '@commercetools/frontend-domain-types/product/Result';
import { State, PostalCodeInfo } from 'types/country';

const useGeoData = (): UseGeoDataReturn => {
  const globalContext = useContext(GlobalContext);
  const { countries, setCountries, getCountries } = globalContext;
  const [states, setStates] = useState<State[]>([] as unknown as State[]);
  const [postalCodeInfo, setPostalCodeInfo] = useState<PostalCodeInfo>();

  const getStates = useCallback(async (countryId: number) => {
    const res = await sdk.callAction({ actionName: 'geodata/getStates', query: { countryId } });
    const resStates = res.isError ? [] : (res?.data as Result as unknown as State[]);
    setStates(resStates);
    return resStates;
  }, []);

  const getPostalCodeInfo = useCallback(async (postalCode: string, countryCode?: string) => {
    const res = await sdk.callAction({ actionName: 'geodata/getPostalCodeInfo', query: { postalCode } });
    const resPostalCodeInfo = res.isError ? undefined : (res?.data as Result as unknown as PostalCodeInfo);
    setPostalCodeInfo(resPostalCodeInfo);
    return resPostalCodeInfo;
  }, []);

  return {
    countries,
    states,
    postalCodeInfo,
    setCountries,
    getCountries,
    getStates,
    setStates,
    getPostalCodeInfo,
    setPostalCodeInfo,
  };
};

export default useGeoData;
