import React, { useCallback, useState } from 'react';
import { Cart } from 'types/cart';
import { sdk } from 'sdk';
import { Category } from 'types/category';
import { Country } from 'types/country';
import { Result } from '@commercetools/frontend-domain-types/product';

const GlobalContext = React.createContext({
  cart: {} as unknown as Cart,
  allCategories: {} as unknown as Category[],
  countries: {} as any[],
  setCart: (cart: Cart) => {},
  getCart: () => {},
  setAllCategories: (allCategories: Category[]) => {},
  getAllCategories: () => {},
  setCountries: (countries: any[]) => {},
  getCountries: () => {},
});

const GlobalCTProvider: React.FC = ({ children }) => {
  const extensions = sdk.composableCommerce;
  const [cart, setCart] = useState<Cart>({} as unknown as Cart);
  const [allCategories, setAllCategories] = useState<Category[]>({} as unknown as Category[]);
  const [countries, setCountries] = useState<Country[]>([] as unknown as Country[]);

  const getCart = useCallback(async () => {
    const res = await extensions.cart.getCart();
    const current = (res.isError ? {} : res.data) as Cart;
    setCart(current);
  }, []);

  const getAllCategories = useCallback(async () => {
    const res = await sdk.callAction({ actionName: 'product/getAllCategories', query: { limit: 200 } });
    const cats = res.isError ? [] : ((res?.data as Result).items as Category[]);
    setAllCategories(cats);
  }, []);

  const getCountries = useCallback(async () => {
    const res = await sdk.callAction({ actionName: 'geodata/getCountries' });
    const countries = res.isError ? [] : (res?.data as Result as unknown as Country[]);
    setCountries(countries);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        cart,
        setCart,
        getCart,
        allCategories,
        setAllCategories,
        getAllCategories,
        countries,
        setCountries,
        getCountries,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalCTProvider };
